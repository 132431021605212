import React from 'react'
import { Link } from "gatsby";
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 50px;
  li {
    margin: 0 8px;
  }
  &.orange {
    .current {
      background: ${({ theme }) => theme.colors.whyOrange};
    }
  }
  &.green {
    .current {
      background: ${({ theme }) => theme.colors.whyGreen};
    }
  }
  &.blue {
    .current {
      background: ${({ theme }) => theme.colors.whyBlue};
    }
  }
  &.yellow {
    .current {
      background: ${({ theme }) => theme.colors.whyYellow};
    }
  }
  &.red {
    .current {
      background: ${({ theme }) => theme.colors.whyMagenta};
    }
  }
  .links {
      text-decoration: none;
      background: none;
      display: block;
      text-align: center;
    }
    .current {
      color: #fff;
      padding: 2px 9px;
    }
    .arrow {
      border: solid 1px;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      height: 10px;
      width: 10px;
      margin: 5px;
    }
    .next {
      transform: rotate(-45deg);
    }
    .prev {
      transform: rotate(135deg);
    }
`

const Pagination= ({
  numPages,
  currentPage,
  currentSlug,
  color
}) => {
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage =
    currentPage - 1 === 1
      ? currentSlug
      : `${currentSlug}/${(currentPage - 1).toString()}`;
  const nextPage = `${currentSlug}/${(currentPage + 1).toString()}`;

  return (
      <Wrapper className={color}>
        {!isFirst && (
          <Link to={`${prevPage}`} rel="prev" className="links arrow prev" />
        )}
        {Array.from({ length: numPages }, (_, i) => (
          <li key={`pagination-number${i + 1}`}>
            <Link
              to={i === 0 ? `${currentSlug}` : `${currentSlug}/${i + 1}`}
              className={i + 1 === currentPage ? "links current" : "links"}
            >
              {i + 1}
            </Link>
          </li>
        ))}
        {!isLast && (
          <Link to={`${nextPage}`} rel="next" className="links arrow next" />
        )}
      </Wrapper>
  );
};

export default Pagination;