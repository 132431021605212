import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import Container from '../components/primary/Container'
import SEO from '../components/primary/SEO'
import ImageCard from '../components/elements/CardWithImage'
import Card from '../components/elements/CardWithTitle'
import CatCard from '../components/elements/CardWithCat'
import Banner from '../components/page-sections/CategoryBanner'
import Layout from '../components/primary/Layout'
import Pagination from '../components/elements/Pagination'
import CategoryCard from '../components/elements/CategoryCard'

const Background = styled.div`
  background-color: #fafafa;
  padding: 50px 0;
`
const NoArticles = styled.h4`
  margin: 30px auto;
  width: 90%;
  text-align: center;
`

const Browse = styled.div`
  padding: 60px 0 90px;
  h2 {
    padding-bottom: 30px;
  }
  h3 {
    font-size: 26px;
    @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
      font-size: 28px;
    }
  }
  &.orange {
    background-color: rgba(242, 132, 22, 0.2);
    h2 {
      color: ${({ theme }) => theme.colors.whyOrange};
    }
  }
  &.green {
    background-color: rgba(141, 145, 64, 0.2);
    h2 {
      color: ${({ theme }) => theme.colors.whyGreen};
    }
  }
  &.blue {
    background-color: rgba(14, 77, 128, 0.2);
    h2 {
      color: ${({ theme }) => theme.colors.whyBlue};
    }
  }
  &.yellow {
    background-color: rgba(245, 185, 6, 0.2);
    h2 {
      color: ${({ theme }) => theme.colors.whyYellow};
    }
  }
  &.red {
    background-color: rgba(182, 36, 98, 0.2);
    h2 {
      color: ${({ theme }) => theme.colors.whyMagenta};
    }
  }
`

const Flex = styled.div`
  display: grid;
  margin: 0 auto;
  padding: 0;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 20px;
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    grid-gap: 40px;
  }
`
const ArticleWrap = styled.div`
  display: grid;
  margin: 0 auto;
  margin-top: -125px;
  margin-bottom: 45px;
  padding: 0 10px;
  grid-template-columns: repeat(auto-fill, minmax(265px, 1fr));
  grid-row-gap: 70px;
  grid-column-gap: 25px;
  &.empty {
    margin-top: 0;
    display: flex;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
    padding: 0;
  }
`

const SubCategory = ({
  data: { articles, subcategory, subcategories },
  pageContext: { numPages, currentPage, limit }
}) => {
  const subcat = subcategory.data.name
  const allSubcats = subcategories.nodes
  const parentcat = subcategory.data?.parent_category?.document
  let subcatFiltered = allSubcats.filter((sub) => {
    if (sub.data.name !== subcat) {
      return sub.data.parent_category.uid === parentcat?.uid
    }
  })
  const color =
    parentcat?.data.color !== 'none' && parentcat
      ? parentcat?.data.color
      : 'blue'
  const parentUid = parentcat?.uid
  const parentName = parentcat?.data.name
  subcatFiltered = subcatFiltered.sort(() => Math.random() - 0.5)
  const browseSubcats = subcatFiltered.slice(0, 4)

  return (
    <Layout color={color}>
      <SEO
        title={`${subcat}`}
        description={`Articles related to the ${subcat} category`}
      />
      <Banner
        name={subcat}
        description={subcategory.data.description}
        color={color}
        parentUid={parentUid}
        parentName={parentName}
        subcat
        category={subcat}
      />

      {parentcat?.data.category_group === 'Conversations' ? (
        <Background>
          <Container width="986px">
            <ArticleWrap className={articles.edges.length > 0 ? '' : 'empty'}>
              {articles.edges.length > 0 ? (
                articles.edges.map(({ node }) => (
                  <ImageCard
                    key={node.uid}
                    color={color}
                    title={node.data.title}
                    link={`/stories/${node.uid}`}
                    image={node.data.featured_image}
                  />
                ))
              ) : (
                <NoArticles>No articles in {subcat} yet!</NoArticles>
              )}
            </ArticleWrap>
          </Container>
          {numPages > 1 && (
            <Pagination
              numPages={numPages}
              currentPage={currentPage}
              currentSlug={`/${subcategory.uid}`}
              color={color}
            />
          )}
        </Background>
      ) : (
        <Background>
          {articles.edges.length > 0 ? (
            articles.edges.map(({ node }) => (
              <Card
                key={node.uid}
                color={color}
                title={node.data.title}
                link={`/stories/${node.uid}`}
              />
            ))
          ) : (
            <NoArticles>No articles in {subcat} yet!</NoArticles>
          )}
          {numPages > 1 && (
            <Pagination
              numPages={numPages}
              currentPage={currentPage}
              currentSlug={`/${subcategory.uid}`}
              color={color}
            />
          )}
        </Background>
      )}

      {browseSubcats.length > 0 && (
        <Browse className={color}>
          <Container width="1175px">
            <h2>Browse more categories</h2>
            <Flex>
              {browseSubcats.map((node) => (
                <CatCard
                  key={node.uid}
                  color={color}
                  title={node.data.name}
                  link={`/${node.uid}`}
                />
              ))}
            </Flex>
          </Container>
        </Browse>
      )}
    </Layout>
  )
}

export default SubCategory

export const SubcategoryQuery = graphql`
  query SubcategoryQuery($uid: String, $skip: Int!, $limit: Int!) {
    articles: allPrismicArticle(
      filter: {
        data: {
          subcategories: { elemMatch: { subcategory: { uid: { eq: $uid } } } }
        }
      }
      sort: { order: DESC, fields: data___created }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          uid
          data {
            title
            featured_image {
              alt
              fluid(maxWidth: 280) {
                ...GatsbyPrismicImageFluid
              }
            }
            created
            categories {
              category {
                uid
                slug
              }
            }
            subcategories {
              subcategory {
                document {
                  ... on PrismicCategory {
                    uid
                    data {
                      color
                      name
                    }
                  }
                }
              }
            }
            featured_image {
              alt
              fluid(maxWidth: 280) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    }
    subcategories: allPrismicSubcategory {
      nodes {
        uid
        data {
          name
          parent_category {
            uid
          }
        }
      }
    }
    subcategory: prismicSubcategory(uid: { eq: $uid }) {
      data {
        description
        name
        parent_category {
          document {
            ... on PrismicCategory {
              data {
                color
                name
                category_group
              }
              uid
            }
          }
        }
      }
      uid
    }
  }
`
